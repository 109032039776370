<template>
  <div class="navs" ref="head">
    <div class="top">
      <!-- <div class="top" @scroll="handleScroll(e)"> -->
      <div class="content">
        <div class="logo wow animate__flipInX" @click="changeNav()" data-wow-delay='0.2s'>
          <img src="@/assets/img/liangfangLogo.png" alt="" v-if="!logo">
          <img :src="logo" alt="" v-else>
        </div>
        <div class="right">
          <nav class='nav'>
            <ul class='nav__list'>
              <li class='nav__menu  wow animate__lightSpeedInRight' data-wow-delay='0.1s'
                @click="changeNav('aboutLiangYi')" :class="{ activeFont: routerValue == 'aboutLiangYi' }">关于良医
                <ul class='nav__menu-lists nav__menu--5-lists'>
                  <li class='nav__menu-items' @click="getNav(1)">集团简介</li>
                  <li class='nav__menu-items' @click="getNav(2)" >企业文化</li>
                  <li class='nav__menu-items' @click="getNav(3)">发展历程</li>
                  <li class='nav__menu-items' @click="getNav(4)">领导团队</li>
                </ul>
              </li>
              <li class='nav__menu wow animate__lightSpeedInRight' data-wow-delay='0.2s'
                :class="{ activeFont: routerValue == 'projectIntroduction' }">项目介绍
                <ul class='nav__menu-lists nav__menu--5-lists'>
                  <li class='nav__menu-items'  @click="redirectToDomain('1')">超医</li>
                  <li class='nav__menu-items'  @click="redirectToDomain('2')">米墨</li>
                  <li class='nav__menu-items'  @click="redirectToDomain('3')">米墨电商</li>
                </ul>
              </li>
              <li class='nav__menu wow animate__lightSpeedInRight' @click="changeNav('enterpriseService')"
                data-wow-delay='0.3s' :class="{ activeFont: routerValue == 'enterpriseService' }">企业服务
              </li>
              <li class='nav__menu wow animate__lightSpeedInRight' data-wow-delay='0.4s' @click="changeNav('pressCenter')"
                :class="{ activeFont: routerValue == 'pressCenter' }">新闻中心
              </li>
              <li class='nav__menu wow animate__lightSpeedInRight' @click="changeNav('contact')" data-wow-delay='0.5s'
                :class="{ activeFont: routerValue == 'contact' }">联系我们
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { WOW } from 'wowjs'
export default {
  props: ['routerValue'],
  name: 'navigation',
  data() {
    return {
      i: 0,
      scrollHeight: 0, //当前滚轮高度
      metaHeight: 15,  //最小滚动距离
      metaTime: 10,   //最小执行时间
      targetHeight: 0,     //目标区域滚轮高度
      logo:''
    }
  },
  methods: {
    redirectToDomain(index) {
      switch (index) {
        case '1':
          window.open('https://edr.90c.la/index.html', '_blank');
          break;
          case '2':
          window.open('https://mimo.lyjtxa.com/#/Home', '_blank');
          break;
        default:
          window.open('https://mimoec.lyjtxa.com/#/Home', '_blank');
          break;
      }
    },
    getNav(index) {
      switch (index) { // 匹配不同区域的滚轮高度
        case 1: //区域一
          this.targetHeight = 300  //这里将第一步获取到的滚轮高度取整
          break;
        case 2: //区域二
          this.targetHeight = 800
          break;
        case 3: //区域三
          this.targetHeight = 1500
          break;
        case 4: //区域四
          this.targetHeight = 2200
          break;
        default: //默认：区域一
          this.targetHeight = 0
          break;
      }
      // 判断执行情况
      // 当指定区域和当前滚动条位置一致时
      if ((document.documentElement.scrollTop === 300 && this.targetHeight === 300) ||
        (document.documentElement.scrollTop === 800 && this.targetHeight === 800) ||
        (document.documentElement.scrollTop === 1500 && this.targetHeight === 1500)) {
      }
      // 当指定区域高度大于当前滚动条位置时（即目标区在当前滚轮的下方）
      else if (this.targetHeight > this.scrollHeight) {
        // 计算高度差
        let x = this.targetHeight - this.scrollHeight;
        // 先加上余数，保证高度差能整除设定的最小移动单位
        document.documentElement.scrollTop += x % this.metaHeight;
        x -= x % this.metaHeight;
        const goto = setInterval(() => { // 建立执行操作的定时器
          document.documentElement.scrollTop += this.metaHeight; // 控制移动滚动条
          x -= this.metaHeight; // 缩减高度差
          if (x == 0) { // 到达指定位置后清除定时器
            clearInterval(goto); //清除定时器
          }
        }, this.metaTime);
      }
      // 当指定区域高度小于当前滚动条位置时（即目标区在当前滚轮的上方）
      // else {
      //   // 计算高度差
      //   let x = this.scrollHeight - this.targetHeight;
      //   // 先减去余数，保证高度差能整除设定的最小移动单位
      //   document.documentElement.scrollTop -= x % this.metaHeight;
      //   x -= x % this.metaHeight;
      //   const goto = setInterval(() => {
      //     document.documentElement.scrollTop -= this.metaHeight;
      //     x -= this.metaHeight;
      //     if (x == 0) {
      //       clearInterval(goto); //清除定时器
      //     }
      //   }, 1);
      // }
    },
    handleScroll() {
      var scrollTop = document.documentElement.scrollTop;
      this.scrollHeight = scrollTop;
      // console.log('当前滚轮高度:', this.scrollHeight);
    },
    changeNav(routerValue) {
      if (routerValue) {
        this.$router.push('/' + routerValue);
      } else {
        this.$router.push('/Home');
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    let obj = JSON.parse(localStorage.getItem("webObj"));
    this.logo = obj.logo;
  },
}
</script>

<style scoped lang="scss">
.navs {
  width: 100%;
  height: 100%;

  .activeFont {
    color: #13A3F0;
    // font-weight: 600;
  }

  .top {
    background: #ffffff;
    // transition: 1.5s;
    width: 100%;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;

    .content {
      width: 80%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .logo {
        width: 90px;
        height: 70px;
        flex-grow: 0;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .right {
        // width: 40%;
        height: 100%;

        ul {
          list-style-type: none
        }

        .nav {
          text-align: center;
          width: 100%;
          height: 60px;
          line-height: 60px;
        }

        .nav__list {
          display: inline-block;
        }

        .nav__menu {
          float: left;
          width: 140px;
          height: 60px;
          line-height: 60px;
          text-transform: uppercase;
          cursor: pointer;
          background: none;
          border: 0;
          box-sizing: border-box;
          font-size: inherit;
          position: relative;
          vertical-align: middle;
          transition: color 0.25s;
        }

        .nav__menu::before,
        .nav__menu::after {
          box-sizing: inherit;
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
        }

        .nav__menu::before,
        .nav__menu::after {
          border: 2px solid transparent;
          width: 0;
          height: 0;
        }

        .nav__menu::before {
          top: 0;
          left: 0;
        }

        .nav__menu::after {
          bottom: 0;
          right: 0;
        }

        .nav__menu:hover::before,
        .nav__menu:hover::after {
          width: 100%;
          height: 100%;
        }

        .nav__menu:hover::before {
          border-top-color: #13A3F0;
          border-right-color: #13A3F0;
          transition: width 0.25s ease-out, height 0.25s ease-out 0.25s;
        }

        .nav__menu:hover::after {
          border-bottom-color: #13A3F0;
          border-left-color: #13A3F0;
          transition: border-color 0s ease-out 0.5s, width 0.25s ease-out 0.5s, height 0.25s ease-out 0.75s;
        }

        .nav__menu:hover>.nav__menu-lists li {
          display: block;
        }

        .nav__menu:hover>.nav__menu--5-lists li:nth-child(1) {
          opacity: 0;
          -webkit-animation: menu5 ease-in-out forwards;
          animation: menu5 ease-in-out forwards;
          -webkit-animation-duration: 300ms;
          animation-duration: 300ms;
          -webkit-animation-delay: 0ms;
          animation-delay: 0ms;
        }

        @-webkit-keyframes menu5 {
          0% {
            opacity: 0;
            -webkit-transform: rotateY(90deg);
            transform: rotateY(90deg);
          }

          100% {
            opacity: 1;
            -webkit-transform: rotateY(0);
            transform: rotateY(0);
          }
        }

        @keyframes menu5 {
          0% {
            opacity: 0;
            -webkit-transform: rotateY(90deg);
            transform: rotateY(90deg);
          }

          100% {
            opacity: 1;
            -webkit-transform: rotateY(0);
            transform: rotateY(0);
          }
        }

        .nav__menu:hover>.nav__menu--5-lists li:nth-child(2) {
          opacity: 0;
          -webkit-animation: menu5 ease-in-out forwards;
          animation: menu5 ease-in-out forwards;
          -webkit-animation-duration: 300ms;
          animation-duration: 300ms;
          -webkit-animation-delay: 125ms;
          animation-delay: 125ms;
        }

        @keyframes menu5 {
          0% {
            opacity: 0;
            -webkit-transform: rotateY(90deg);
            transform: rotateY(90deg);
          }

          100% {
            opacity: 1;
            -webkit-transform: rotateY(0);
            transform: rotateY(0);
          }
        }

        .nav__menu:hover>.nav__menu--5-lists li:nth-child(3) {
          opacity: 0;
          -webkit-animation: menu5 ease-in-out forwards;
          animation: menu5 ease-in-out forwards;
          -webkit-animation-duration: 300ms;
          animation-duration: 300ms;
          -webkit-animation-delay: 250ms;
          animation-delay: 250ms;
        }

        @keyframes menu5 {
          0% {
            opacity: 0;
            -webkit-transform: rotateY(90deg);
            transform: rotateY(90deg);
          }

          100% {
            opacity: 1;
            -webkit-transform: rotateY(0);
            transform: rotateY(0);
          }
        }

        .nav__menu:hover>.nav__menu--5-lists li:nth-child(4) {
          opacity: 0;
          -webkit-animation: menu5 ease-in-out forwards;
          animation: menu5 ease-in-out forwards;
          -webkit-animation-duration: 300ms;
          animation-duration: 300ms;
          -webkit-animation-delay: 375ms;
          animation-delay: 375ms;
        }

        @keyframes menu5 {
          0% {
            opacity: 0;
            -webkit-transform: rotateY(90deg);
            transform: rotateY(90deg);
          }

          100% {
            opacity: 1;
            -webkit-transform: rotateY(0);
            transform: rotateY(0);
          }
        }

        .nav__menu:hover>.nav__menu--5-lists li:nth-child(5) {
          opacity: 0;
          -webkit-animation: menu5 ease-in-out forwards;
          animation: menu5 ease-in-out forwards;
          -webkit-animation-duration: 300ms;
          animation-duration: 300ms;
          -webkit-animation-delay: 500ms;
          animation-delay: 500ms;
        }

        @keyframes menu5 {
          0% {
            opacity: 0;
            -webkit-transform: rotateY(90deg);
            transform: rotateY(90deg);
          }

          100% {
            opacity: 1;
            -webkit-transform: rotateY(0);
            transform: rotateY(0);
          }
        }

        .nav__menu-lists {
          color: #000;
          position: absolute;
          z-index: 9;
          padding-top: 30px;
          -webkit-perspective: 5000px;
          perspective: 5000px;
        }

        .nav__menu-items {
          display: none;
          width: 140px;
          height: 60px;
          background-color: #ffffff;
        }

        .nav__menu-items:hover {
          background-color: #f5f5f5;
          color: #13A3F0;
        }
      }
    }
  }

}
</style>
