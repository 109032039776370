import axios from "axios"
import { Loading } from 'element-ui'

let host;
//开发坏境
//process.env.NODE_ENV 实现接口域名一键切换
// if (process.env.NODE_ENV == "development") {
//     host = "https://cms.lyjtxa.com";
// } else {
//     //生产坏境
//     host = "https://cms.lyjtxa.com";
// }

var instance = axios.create({
    baseURL: '/api',
    timeout: 5000,
});
//请求拦截器.在请求发送之前对发送的请求执行某些处理
var loading;
instance.interceptors.request.use(function (config) {
    loading = Loading.service({
        lock: true,
        text: '加载中……',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
    })
    return config;
}, function (error) {
    // 对请求失败做处理
    return Promise.reject(error);
});

instance.interceptors.response.use(function (config) {
    // const code = config.data.code;
    // if (code != 200) {
    //     this.$notify.info({
    //         title: '消息',
    //         message: '出错啦!请刷新页面..'
    //     });
    // }
    loading.close();
    return config.data;
}, function (error) {
    loading.close();
    return Promise.reject(error);
});
export default instance;
