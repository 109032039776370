import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/text/text.css'
import 'animate.css'
import wow from 'wowjs'
import 'wowjs/css/libs/animate.css'
import scroll from 'vue-seamless-scroll'
Vue.use(scroll)
import timelineCarousel from 'timeline-carousel'
Vue.use(timelineCarousel)
Vue.prototype.$wow = wow
Vue.use(ElementUI);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
