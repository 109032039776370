import request from "@/api/http.js";
function banner(params) {
    return request({

        url: '/v1/banner',
        params,

    })
}
function info(params) {
    return request({
        url: '/v1/web/info',
        params,

    })
}
function news(params) {
    return request({
        url: '/v1/news',
        params,

    })
}
function newsId(id) {
    return request({
        url: `/v1/news/${id}`,

    })
}
export {
    banner,
    info,
    news,
    newsId
}