<template>
  <div class="home">
    <Nav></Nav>
    <div class="banner">
      <el-carousel v-if="bannerList.length > 0">
        <el-carousel-item v-for="item in bannerList" :key="item.id">
          <img :src="item.img" alt="" style="width: 100%;height: 900px;">
        </el-carousel-item>
      </el-carousel>
      <el-carousel v-else>
        <el-carousel-item>
          <img src="@/assets/img/banner.jpg" alt="" style="width: 100%;height: 900px;">
        </el-carousel-item>
        <el-carousel-item>
          <img src="@/assets/img/banner2.png" alt="" style="width: 100%;height: 900px;">
        </el-carousel-item>
      </el-carousel>
      <div class="banner_box">
        <div class="title wow animate__fadeInUp">
          <span>创</span>
          <span>新</span>
          <span>数</span>
          <span>智</span>
          <span>赋</span>
          <span>能</span>
          <span>孵</span>
          <span>化</span>
        </div>
        <div class="address wow animate__backInUp">中国 · 西安</div>
        <div data-wow-delay="0.3s" class="company wow animate__backInUp">西安良医网络科技集团有限公司</div>
      </div>
    </div>
    <div class="middle">
      <!-- 公司介绍 -->
      <div class="aboutus">
        <div class="aboutus_left wow animate__fadeInDown">
          <div class="en">ABOUT US</div>
          <div class="en">西安良医网络科技集团有限公司</div>
          <div class="line"></div>
          <div class="introduce">
            西安良医网络科技集团有限公司（简称良医）成立于2014年12月1日，是一家专为医药及泛医药领域初创项目提供“智慧孵化”服务的集团型公司。良医在中国医疗改革不断深入与移动互联网经济快速崛起交织的时代背景下，“让就医不再难”作为企业使命，以建设医药创新项目成长摇篮、共创医药数字化营销公网为愿景，坚持机制创新，集聚一批擅长产学研协同的专业人才、掌握前沿技术的创业者、面向科技创新的投资人，致力建设服务医药领域初创项目的智慧孵化平台。
            良医以“生态布局、聚合发展、数控提效、价值共赢“为发展观：即以客户为中心，以商机吸纳生态资源，实现一站应用；以融资金、融资源、融人才的合伙人机制，聚合成规模优势；以团队效率管理为抓手，量化目标与价值分配融合资源；从客户到合作伙伴到团队转化，形成价值共赢体系，达成知行合一最终践行“让就医不再难”的使命。
          </div>
          <router-link to="/aboutLiangYi">
            <div class="btn-epic  wow animate__fadeInDown">
              <div><span>Skip view</span><span>View more</span></div>
            </div>
          </router-link>
        </div>
        <div class="aboutus_right wow animate__fadeInDown">
          <video  controls="controls"  src="https://liangyijituan.oss-cn-beijing.aliyuncs.com/video/liangyi.mp4"
            ></video>
        </div>
      </div>
      <!-- 企业服务 -->
      <div class="corporats">
        <div class="corporats_top wow animate__fadeInDown">
          <img src="../assets/img/minilogo.png" alt="">
          <div class="serve">良医企业服务</div>
          <div>Corporate Services</div>
          <div>
            <router-link to="/enterpriseService">
              <span class="text">了解更多 <i class="el-icon-arrow-right"></i></span>
            </router-link>
          </div>
        </div>
        <div class="title wow animate__fadeInDown">
          良医集团聚焦医药产业，汲取不同类型企业孵化器模式精髓，博采众长，开创医药产业智慧孵化器模式。持股孵化，与小企业、创业者一起成长。提供增值服务，让初创企业专注于产品和业务。最终通过为小企业创造价值而获得相关收益。
        </div>
        <el-divider></el-divider>
        <div class="corporats_bottom wow animate__fadeInDown">
          <div class="box">
            <img src="../assets/img/serveLift1.png" alt="">
            <div class="inner_box">
              <div>企业孵化器的发展历史</div>
              <div>
                1959年，美国有一位叫约瑟夫·曼库索的商人，经营着一栋85万平方英尺的大楼，后来他决定把这个大楼分隔成小单元，分别租给不同的小企业，并且向这些小企业提供融资、咨询等服务来促进它们成长。后来，曼库索从大楼内一家养鸡企业活蹦乱跳的...
              </div>
            </div>
          </div>
          <div class="box">
            <img src="../assets/img/serveRight1.png" alt="">
            <div class="inner_box">
              <div>企业孵化器的模式断代史</div>
              <div>
                V2.0版本的企业孵化器除了出租办公场地之外，还可以为初创企业提供一些基本的企业服务，例如：公司注册、财务代理、政策申报、科技成果、导师辅导、资本对接等等。对于资金与经验缺乏的初创企业来说，能一定程度上减少初创项目的创业成本、加快创业速度...
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 项目介绍 -->
    <div class="project">
      <div class="project_inner">
        <div class="corporats_top wow animate__fadeInDown">
          <img src="../assets/img/minilogo.png" alt="">
          <div class="serve">良医项目介绍</div>
          <div>Project Introduction</div>
        </div>
        <!-- Swiper -->
        <div class="swiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide swiper-no-swiping">
              <div class="project_box wow animate__fadeInDown">
                <div class="project_left">
                  <div class="logo">
                    <img src="../assets/img/mimoLogo.png" class="logo_img " alt="">
                  </div>
                  <div class="mimo">
                    米黑MIMO医药互联网推广平台以“互联网+大数据”为技术载体，通过构建“标准化学术交易+商机撮合+服务溯源+财税筹划”的综合服务模式，帮助医药行业的企业、从业者打破发展壁垒，实现营销数字化、智能化、精准化升级，并提供转型资源，实现全面合规的可持续发展状态。
                  </div>
                </div>
                <div class="project_right">
                  <img src="../assets/img/project.png" alt="">
                </div>
              </div>
            </div>
            <div class="swiper-slide swiper-no-swiping">
              <div class="project_box wow animate__fadeInDown">
                <div class="project_left">
                  <div class="logo">
                    <img src="../assets/img/commerce.png" class="logo_img " alt="">
                  </div>
                  <div class="mimo">
                    米墨电商运营团队创建于2018年,现拥有运营团队近80人,是全国医药行业最具有影响力的全网营销运营服务商之一 ;主营业务包括B2C渠道管理、内容营销、电商营销等业务。<br>能够帮助药企解决的网络乱价问题,净网周期一般为三到四个月;通过全网布网以及营销策划实现营销增量。从内容优化和电商销量两个最基础需求出发,多平台共创战路,拓宽营销的深度与广度,为客户提供覆盖营销各个环节的全方位服务。 
                  </div>
                </div>
                <div class="project_right">
                  <img src="../assets/img/mimoCommerce.png" alt="">
                </div>
              </div>
            </div>
            <div class="swiper-slide swiper-no-swiping">
              <div class="project_box wow animate__fadeInDown">
                <div class="project_left">
                  <div class="logo">
                    <img src="../assets/img/superDoctor.png" class="logo_img " alt="">
                  </div>
                  <div class="mimo">
                    超医助手直译为超级医生助手。超医助手是良医集团旗下的互联网医疗学术资源聚合平台。超医助手以医生用户为中心，为医生用户提供专业的医学内容资源、前沿的行业资讯、便捷的医疗执业工具，致力成为医生用户最好用最便捷的职业app，助力医生成为超级医生。
                  </div>
                </div>
                <div class="project_right">
                  <img src="../assets/img/Assistant.png" alt="">
                </div>
              </div>
            </div>
          </div>
          <!-- Add Arrows -->
          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>
        </div>
      </div>
    </div>
    <!-- 新闻中心 -->
    <div class="news">
      <div class="center" v-if="list.length>0">
        <div class="corporats_top wow animate__fadeInDown">
          <img src="../assets/img/minilogo.png" alt="">
          <div class="serve">良医新闻中心</div>
          <div>NEWS CENTER</div>
          <div>
            <router-link to="/pressCenter"><span class="text">了解更多 <i class="el-icon-arrow-right"></i></span>
            </router-link>
          </div>
        </div>
        <div class="newsList">
          <div class="newsList_left wow animate__fadeInDown">
            <img class="newsImg" :src="news.cover" alt="">
            <div class="mock">
              <div class="convention">
                <div class="title">
                  <img src="../assets/img/qiye.png" alt="">
                  <div class="change">{{ news.newsColumn }}</div>
                </div>
                <div class="shareholder change">{{ news.title }}</div>
                <div class="description change">
                  {{ news.seoAbstract }}</div>
                <div class="time">
                  <div class="date">
                    <img src="../assets/img/riqi.png" alt="">
                    <div class="change">{{ news.updateTime }}</div>
                  </div>
                  <div class="info" @click="goNews(news.id)">
                    <div><span class="text change">查看详情<i class="el-icon-arrow-right"></i></span> </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="newsList_right wow animate__fadeInDown">
            <div class="top" v-for="item in list.slice(1, 3)" :key="item.id">
              <div class="newsImg_left">
                <img class="newsImg" :src="item.cover" alt="">
              </div>
              <div class="convention">
                <div class="title">
                  <img src="../assets/img/qiye.png" alt="">
                  <div>{{ item.newsColumn }}</div>
                </div>
                <div class="shareholder">{{ item.title }}</div>
                <div class="description">{{ item.seoAbstract }}
                </div>
                <div class="time">
                  <div class="date">
                    <img src="../assets/img/riqi.png" alt="">
                    <div>{{ item.updateTime }}</div>
                  </div>
                  <div class="info" @click="goNews(item.id)">
                    <div><span class="text">查看详情<i class="el-icon-arrow-right"></i></span> </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 合作伙伴 -->
      <div class="cooperation">
        <div class="corporats_top wow animate__fadeInDown">
          <img src="../assets/img/minilogo.png" alt="">
          <div class="serve">良医合作伙伴</div>
          <div>COOPERATIVE PARTNER</div>
        </div>
        <div class="partner">
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div class="swiper-slide swiper-no-swiping">
                <img src="../assets/img/swiper1.png" alt="">
              </div>
              <div class="swiper-slide swiper-no-swiping">
                <img src="../assets/img/swiper2.png" alt="">
              </div>
              <div class="swiper-slide swiper-no-swiping">
                <img src="../assets/img/swiper3.png" alt="">
              </div>
              <div class="swiper-slide swiper-no-swiping">
                <img src="../assets/img/swiper4.png" alt="">
              </div>
              <div class="swiper-slide swiper-no-swiping">
                <img src="../assets/img/swiper5.png" alt="">
              </div>
              <div class="swiper-slide swiper-no-swiping">
                <img src="../assets/img/swiper6.png" alt="">
              </div>
              <div class="swiper-slide swiper-no-swiping">
                <img src="../assets/img/swiper7.png" alt="">
              </div>
              <div class="swiper-slide swiper-no-swiping">
                <img src="../assets/img/swiper8.png" alt="">
              </div>
              <div class="swiper-slide swiper-no-swiping">
                <img src="../assets/img/swiper9.png" alt="">
              </div>
            </div>
          </div>
        </div>
        <div>
          <el-backtop :bottom="450" :visibility-height="3500">
            <div style="{
                height: 100%;
                width: 100%;
                background-color: #f2f5f6;
                box-shadow: 0 0 6px rgba(0,0,0, .12);
                text-align: center;
                line-height: 40px;
                color: #1989fa;
              }">
              UP
            </div>
          </el-backtop>
        </div>
      </div>
    </div>
    <btm></btm>
  </div>
</template>

<script>
import Swiper, { Navigation, Pagination, Autoplay } from "swiper";
import "swiper/swiper-bundle.css";
Swiper.use([Autoplay, Navigation, Pagination]);
import { WOW } from 'wowjs'
import Nav from '@/components/Navigation.vue'
import btm from '@/components/bottom.vue'
import { banner, news } from "@/api/ly"
export default {
  name: 'Home',
  components: {
    Nav,
    btm
  },
  data() {
    return {
      bannerList: [],
      list: [],
      newsList: {
        pageNum: 1,
        pageSize: 3,
        showLocation:1
      },
      news: {},
    }
  },
  methods: {
    goNews(id) {
      this.$router.push({ name: 'newsDetails', params: { id: id } })
    },
    initSwiper() {
      var swiper = new Swiper('.swiper-container', {
        speed: 4000,
        slidesPerView: 5,
        spaceBetween: 30,
        loop: true,
        loopedSlides: 4,
        loopAdditionalSlides: 2,
        allowTouchMove: false,
        autoplay: {
          delay: 1,
          // stopOnLastSlide: false,
          disableOnInteraction: false,
        }
      });
    }
  },
  mounted() {
    this.$nextTick(() => {
      new WOW({ live: true }).init();
      this.initSwiper()
    })

    new Swiper('.swiper', {
      speed: 1000,
      spaceBetween: 100,
      loopPreventsSlide: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  },
  created() {
    banner().then(res => {
      if (res.code == 200) {
        this.bannerList = res.data;
      }
    });
    news(this.newsList).then(res => {
      if (res.code == 200) {
        this.list = res.rows;
        this.news = this.list[0]
      }
    })
  }
}
</script>
<style scoped lang="scss">
::v-deep .el-carousel__container {
  height: 900px;
}

.router-link-active {
  text-decoration: none;
}

a {
  text-decoration: none;
}

.home {
  width: 100%;
  height: 100%;

  // .nav {
  //   width: 100%;
  //   height: 120px;
  //   // background-color: #ffffff;
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   z-index: 10;
  // }

  .banner {
    width: 100%;
    height: 900px;

    .banner_box {
      position: absolute;
      left: 50%;
      top: 25%;

      transform: translateX(-50%);
      z-index: 2;
      text-align: center;
      width: 50%;
      color: #ffffff;

      .title {
        font-size: 70px;
        font-weight: 400;
        line-height: 97px;
        font-family: 'gfzt';
        letter-spacing: 10px;

        :nth-child(2n) {
          color: #91D200;
        }

        :nth-child(2n-1) {
          margin-left: 35px;
        }

      }

      .address {
        margin-top: 450px;
        font-size: 30px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 45px;
      }

      .company {
        margin-top: 25px;
        font-size: 30px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 45px;
      }
    }
  }

  // 版心内容区域
  .middle {
    width: 1500px;
    margin: 0 auto;

    .aboutus {
      padding-top: 100px;
      width: 100%;
      height: 700px;
      display: flex;
      justify-content: space-between;
      background: url('../assets/img/about_bg.png') no-repeat bottom;

      .aboutus_left {
        width: 40%;

        .en {
          font-size: 30px;
          font-family: Georgia;
          font-weight: bold;
          color: #333333;
        }

        .line {
          margin: 30px 0 40px;
          width: 30px;
          height: 4px;
          background: #13A3F0;
        }

        .introduce {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #7E7E7E;
          line-height: 30px;
        }

        .btn-epic {
          // border-radius: 25px;
          margin-top: 50px;
          position: relative;
          border: 1px solid #13A3F0;
          width: 100%;
          max-width: 260px;
          height: 52px;
          background: #FFFFFF;
          transform: translate3d(0px, 0%, 0px);
          text-decoration: none;
          font-weight: 600;
          font-size: 18px;
          letter-spacing: 0.05em;
          transition-delay: 0.6s;
          overflow: hidden;
          cursor: pointer;

          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #13A3F0;
            border-radius: 50% 50% 0 0;
            transform: translateY(100%) scaleY(0.5);
            transition: all 0.6s ease;
          }

          &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #fff;
            border-radius: 0;
            transform: translateY(0) scaleY(1);
            transition: all 0.6s ease;
          }

          div {
            position: relative;
            top: 16px;
            width: 100%;
            height: 26px;
            text-transform: uppercase;
            overflow: hidden;
          }

          span {
            position: absolute;
            z-index: 1;
            top: 0;
            width: 100%;
            text-align: center;
            transition: transform 0.5s ease;
          }

          span:first-child {
            color: #fff;
            transform: translateY(24px);
          }

          span:last-child {
            color: #1e0f21;
            transform: translateY(0);
          }

          &:hover {
            background: #13A3F0;
            transition: background 0.2s linear;
            transition-delay: 0.6s;
          }

          &:hover:after {
            border-radius: 0 0 50% 50%;
            transform: translateY(-100%) scaleY(0.5);
            transition-delay: 0;
          }

          &:hover:before {
            border-radius: 0;
            transform: translateY(0) scaleY(1);
            transition-delay: 0;
          }

          &:hover span:first-child {
            transform: translateY(0);
          }

          &:hover span:last-child {
            transform: translateY(-24px);
          }
        }
      }

      .aboutus_right {
        width: 55%;
        height: 550px;

        video {
          width: 100%;
          height: 100%;
          object-fit: fill;
          border-radius: 10px;
        }
      }
    }

    .corporats {
      margin: 100px 0;

      .corporats_top {
        display: flex;
        align-items: flex-end;

        .serve {
          margin-left: 10px;
          font-size: 30px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #333333;
        }

        :nth-child(3) {
          margin-left: 10px;
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #13A3F0;
        }

        :last-child {
          flex-grow: 10;
          text-align: right;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #B3B3B3;

          .text {
            &:hover {
              color: #13A3F0;
              cursor: pointer;

              .el-icon-arrow-right {
                color: #13A3F0;
              }
            }
          }
        }
      }

      .title {
        margin-top: 15px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #6E6F72;
      }

      .el-divider--horizontal {
        margin: 40px 0;
      }

      .corporats_bottom {
        height: 410px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .box {
          overflow: hidden;
          position: relative;
          width: 49%;
          height: 100%;

          &:hover {
            .inner_box {
              transform: translateY(-200px);
              transition: 0.9s;
            }

            img {
              transform: scale(1.2);
              transition: 0.9s;
            }
          }

          img {
            width: 100%;
            height: 100%;
            transition: 0.9s;
          }

          .inner_box {
            position: absolute;
            bottom: -200px;
            left: 85px;
            width: 460px;
            height: 130px;
            background: #FFFFFF;
            opacity: 0.8;
            border-radius: 20px 20px 0px 0px;
            padding: 29px 50px 41px;
            transition: 0.9s;

            :first-child {
              font-size: 22px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #4A4A4D;
            }

            :last-child {
              margin-top: 30px;
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #7C7D80;
              line-height: 22px;
            }
          }
        }
      }
    }
  }

  .project {
    overflow: hidden;
    width: 100%;
    background: #F7FAFF;
    padding: 100px 0;

    .project_inner {
      width: 1500px;
      height: 100%;
      margin: 0 auto;

      .swiper-button-next {
        position: absolute;
        // bottom: -160px;
        transform: translateY(150px);
        left: 100px;
        width: 70px;
        height: 70px;
        background: #ffffff;
        border-radius: 50%;
      }

      .swiper-button-prev {
        position: absolute;
        // bottom: -160px;
        transform: translateY(150px);
        left: 0;
        width: 70px;
        height: 70px;
        background: #ffffff;
        border-radius: 50%;
      }

      .corporats_top {
        display: flex;
        align-items: flex-end;
        margin-bottom: 100px;

        .serve {
          margin-left: 10px;
          font-size: 30px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #333333;
        }

        :last-child {
          margin-left: 10px;
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #13A3F0;
        }
      }

      .project_box {
        width: 100%;
        height: 400px;
        display: flex;
        justify-content: space-between;

        .project_left {
          width: 40%;
          height: 100%;

          .mimo {
            margin-top: 38px;
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #646464;
            line-height: 28px;
          }
        }

        .project_right {
          width: 55%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

  }

  .news {
    width: 100%;

    .center {
      width: 1500px;
      // height: 600px;
      margin: 0 auto;
      padding-bottom: 100px;

      .corporats_top {
        margin: 100px 0 40px;
        display: flex;
        align-items: flex-end;

        .serve {
          margin-left: 10px;
          font-size: 30px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #333333;
        }

        :nth-child(3) {
          margin-left: 10px;
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #13A3F0;
        }

        :last-child {
          flex-grow: 10;
          text-align: right;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #B3B3B3;

          .text {
            &:hover {
              color: #13A3F0;
              cursor: pointer;

              .el-icon-arrow-right {
                color: #13A3F0;
              }
            }
          }
        }
      }

      .newsList {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .newsList_left {
          width: 48%;
          height: 370px;
          position: relative;

          &:hover {
            .mock {
              opacity: 1;
              transition: 0.9s;

              .convention {
                .change {
                  transition: 0.9s;
                  transform: scale(1);
                }
              }
            }
          }

          .newsImg {
            width: 100%;
            height: 100%;
            border-radius: 10px;
          }

          .mock {
            position: absolute;
            left: 0;
            top: 0;
            background: rgb(0, 0, 0, .7);
            width: 100%;
            height: 100%;
            border-radius: 10px;
            opacity: 0;
            transition: 0.9s;

            .convention {
              // height: 100%;
              color: #ffffff;
              font-size: 16px;
              padding: 60px 30px;

              .change {
                transition: 0.9s;
                transform: scale(1.2);
              }

              .title {
                font-family: Source Han Sans CN;
                font-weight: 500;
                display: flex;
                align-items: center;

                :last-child {
                  width: 60%;
                  margin-left: 10px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }

              .shareholder {
                font-family: Source Han Sans CN;
                font-weight: 400;
                margin: 15px 0 22px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              .description {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: pre-wrap;
                height: 42px;
                width: 100%;
                font-family: Source Han Sans CN;
                font-weight: 400;
              }

              .time {
                margin-top: 45px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-family: Source Han Sans CN;
                font-weight: 400;

                .info {
                  cursor: pointer;

                  :hover {
                    color: #13A3F0;
                  }
                }

                .date {
                  width: 40%;
                  display: flex;
                  align-items: center;

                  :last-child {
                    margin-left: 10px;
                  }
                }
              }
            }
          }
        }

        .newsList_right {
          width: 50%;
          height: 370px;
          display: flex;
          flex-wrap: wrap;
          align-content: space-between;

          .top {
            width: 100%;
            height: 46%;
            display: flex;
            justify-content: space-between;

            .newsImg_left {
              width: 260px;
              height: 170px;

              img {
                width: 100%;
                height: 100%;
                border-radius: 10px;
              }
            }

            .convention {
              width: 62%;

              .title {
                font-size: 16px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #333333;
                display: flex;
                align-items: center;

                :last-child {
                  margin-left: 10px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  width: 60%;
                }
              }

              .shareholder {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 16px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #333333;
                margin: 5px 0 12px;
              }

              .description {
                width: 100%;
                height: 56px;
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #666666;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: pre-wrap;
              }

              .time {
                margin-top: 35px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #808080;

                .info {
                  cursor: pointer;

                  :hover {
                    color: #13A3F0;
                  }
                }

                .date {
                  width: 40%;
                  display: flex;
                  align-items: center;

                  :last-child {
                    margin-left: 10px;
                  }
                }
              }
            }
          }

        }
      }
    }

    .cooperation {
      width: 1500px;
      height: 400px;
      background: url("../assets/img/partnerBg.png") no-repeat top;
      margin: 0 auto;

      // padding-bottom: 100px;
      .corporats_top {
        margin-top: 50px;
        display: flex;
        align-items: flex-end;

        .serve {
          margin-left: 10px;
          font-size: 30px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #333333;
        }

        :nth-child(3) {
          margin-left: 10px;
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #13A3F0;
        }
      }

      .partner {
        margin-top: 120px;
        width: 100%;
        overflow: hidden;
      }

      .swiper-wrapper {
        /* 这里是改变其动画效果为匀速形式，不加此样式的话会造成滚动卡顿，看起来效果不平滑 */
        /* 样式做了各种浏览器的兼容 */
        transition-timing-function: linear !important;
        -webkit-transition-timing-function: linear !important;
        -moz-transition-timing-function: linear !important;
        -ms-transition-timing-function: linear !important;
        -o-transition-timing-function: linear !important;
        transition-timing-function: linear !important;
      }

      .swiper-slide {
        width: 170px;
        height: 86px;
        // background: #FFFFFF;
        border: 1px solid #EFEFEF;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>